<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item>
          <template slot="label">
            <l-select v-model="condition.dateType" :all="false" :data="dateTypeList"></l-select>
          </template>
          <l-datepicker type="date" v-model="condition.startDate"></l-datepicker>至
          <l-datepicker type="date" v-model="condition.endDate"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.deviceNo" size="small" placeholder="设备编号"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.carNo" size="small" placeholder="车牌号"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.vin" size="small" placeholder="车架号"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="carId" slot-scope="scope">
          <span v-if="scope.row.carInfo">{{ scope.row.carInfo.carNo || scope.row.carInfo.vin }}</span>
        </template>
        <template slot="chargeEnable" slot-scope="scope">
          <span v-if="scope.row.chargeEnable === 0" class="text-orange">车载接电</span>
          <span v-else-if="scope.row.chargeEnable === 1" class="text-blue">USB充电</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData" :simple="true"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'CarList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '设备编号', prop: 'deviceNo' },
          { label: '车牌号', prop: 'carNo' },
          { label: '车架号', prop: 'vin' },
          { label: 'GPS时间', prop: 'gpsTime' },
          { label: '上报时间', prop: 'trackTime' },
          { label: 'Lng', prop: 'lng' },
          { label: 'Lat', prop: 'lat' }
        ]
      },
      condition: {
        carNo: '',
        vin: '',
        deviceNo: '',
        dateType: 'gpsTime',
        startDate: this.$lgh.getDate(),
        endDate: this.$lgh.getDate(),
        pageIndex: 1,
        pageSize: 20
      },
      pageData: null,
      dateTypeList: [
        { code: 'gpsTime', text: '定位时间' },
        { code: 'trackTime', text: '上报时间' }
      ]
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/platform/gps/data/list', [this.condition])
    }
  }
}
</script>
